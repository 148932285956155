.frame {
 pointer-events: none; 
  }
  
  .line {
    position: absolute;
    background-color: red;
  }
  
  .top, .bottom {
    height: 2px;
    left: 0;
    right: 0;
  }
  
  .top {
    top: 0;
  }
  
  .bottom {
    bottom: 0;
  }
  
  .left, .right {
    width: 2px;
    top: 0;
    bottom: 0;
  }
  
  .left {
    left: 0;
  }
  
  .right {
    right: 0;
  }
  
  .circle {
    pointer-events: all; 
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    border :1px solid red;
    transition: all 0.2s ease-in-out;
  }


  .circle:hover{
    transform: scale(2.5);
  }
  
  .top-left {
    top: -5px;
    left: -5px;
  }
  
  .top-middle {
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
  }

  .middle {
  
    left: 50%;
    transform: translateX(-50%);
    top: 50%;

    transform: translateY(-50%);
  }
  
  
  .top-right {
    top: -5px;
    right: -5px;
  }
  
  .bottom-left {
    bottom: -5px;
    left: -5px;
  }

  .bottom-middle {
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .bottom-right {
    bottom: -5px;
    right: -5px;
  }
  
  .middle-left {
    top: 50%;
    left: -15px;
    transform: translateY(-50%);
  }
  
  .middle-right {
    top: 50%;
    right: -15px;
    transform: translateY(-50%);
  }
  