.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
 
}

.App-header {
  background-color: #FCFCFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.containerList>.childContainer:nth-of-type(odd) {
  background: #00000011 ;
}

button {
  background-color: black !important;
}

.button-active {
  /* Styles pour le bouton actif */
  background-color: green;
  cursor: pointer;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  color : white;
  transition: transform 0.1s ease; /* Ajout d'une transition pour l'effet de scale */
  
}
.button-active:hover {
  transform: scale(0.9); /* Réduction à 80% sur hover */
}

.button-inactive {
  /* Styles pour le bouton inactif */
  background-color: gray;
  cursor: not-allowed;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
}