 @tailwind base;
@tailwind components;

/* @tailwind utilities;*/
@tailwind variants;
html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  height: 100%; /* Assure que le document occupe tout l'espace disponible */
  margin: 0;    /* Supprime les marges par défaut */
  overflow: auto; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




.text-shadow {
/*  -webkit-text-stroke: 1px white;*/

   text-shadow:
       3px 3px 0 #FFF,
     -1px -1px 0 #FFF,  
      1px -1px 0 #FFF,
      -1px 1px 0 #FFF,
       1px 1px 0 #FFF;
}

.-body {
  padding: env(safe-area-inset-top, 20px) env(safe-area-inset-right, 20px) env(
      safe-area-inset-bottom,
      20px
    ) env(safe-area-inset-left, 20px);
}



.safearea {
  padding: env(safe-area-inset-top, 20px) env(safe-area-inset-right, 20px) env(
    safe-area-inset-bottom,
    20px
  ) env(safe-area-inset-left, 20px);
}


.safearea-top {
  padding: env(safe-area-inset-top, 20px) env(safe-area-inset-right, 20px) env(safe-area-inset-left, 20px);
}


.safearea-bottom {
  padding:  env(safe-area-inset-right, 20px) env(
    safe-area-inset-bottom,
    20px
  ) env(safe-area-inset-left, 20px);
}

.normal-container{
transform: scale(1);
transform-origin: center;

}


.snap-container{
 

  transform: scale(1);
transform-origin: center;
}
.container_template{ 

scrollbar-width: none;
display:flex;
align-items: center; 

overflow-x:auto ;
overflow-y: hidden;

}




.wireframe   .templateX{
  border-width : 1px ;
  border-style: dashed ;
  border-color: black;
  transition:all 0.1s ease-out;
}
.selectedElement {
  border-width : 2px !important;
  border-style: dotted !important;
  border-color: #a31616 !important;
  transition:all 0.1s ease-out;
  animation: oscillateborderred 200ms infinite alternate !important; 
}
.hoverElement {
  background-color: orange !important;;
  border-width : 1px !important;;
  border-style:  dashed !important;;
  /*border-color:  #000;
  /*transition:all 0.1s ease-out;*/
  animation: oscillateborder 200ms infinite  !important; 
}
.appbutton,button{

transition:all 0.1s ease-out;
opacity: 1;
transform: scaleY(1);
cursor: pointer;
}
.appbutton:hover,button:hover{ 
  opacity: 0.8;
  transform:  scaleY(0.95);
  }

.appbutton:active,.appbutton:focus ,button:active,button:focus{  
  opacity: 1;
  transform: scaleY(0.91);  }
.loading-text{
   
/*
border-radius: 5px;
  background-color: rgba(0,0,0,0.2);*/
  animation: oscillate 20s infinite alternate; /* Animation infinie */
}


/* Add Building Classes */



@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  60% {
    opacity: 1;
    transform: scale(1.1); /* Étend légèrement */
  }
  100% {
    transform: scale(1); /* Rebond vers sa taille normale */
  }
}

/* Animation de rebond pour la disparition */
@keyframes bounceOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  40% {
    opacity: 0;
    transform: scale(0.9); /* Rétrécit légèrement */
  }
  100% {
    transform: scale(0.5); /* Disparaît rétréci */
    opacity: 0;
  }
}

.dyn_hidden{

  opacity: 0;
  transform: scale(0.5);
  animation: bounceOut 0.6s forwards; 
   width:0;
   height:0;
   padding: 0;
   margin: 0;
}


.dyn_show{


  opacity: 1;
  transform: scale(1);
  animation: bounceIn 0.6s forwards; 



}
.hidden_show ,.dyn_hidden_show{

  opacity: 0.4;
 border : 2px dotted #ccc;
/* filter: grayscale(100%);*/
 background-image: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 10px,
    black 10px,
    black 20px
  );
}


@keyframes oscillate {
  0% {
      opacity: 1; /* Taille initiale */
  }
  50% {
    opacity: 0.5; /* Taille maximale */
  }
  100% {
    opacity: 1; /* Retour à la taille initiale */
  }
}

@keyframes oscillateborder {
  0% {
    border-color :  #FFF !important;
  }
  50% {
    border-color :  #ac1616 !important;
  }
  100% {
    border-color :  #000 !important;
  }
}

@keyframes oscillateborderred {
  0% {
    border-color :  #851f1f !important;
  }
  50% {
    border-color :  #e71818 !important;
  }
  100% {
    border-color :  #851f1f !important;
  }
}



.loading-img{
  width: 100%;
  height:100%;
  background: radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(0,0,0,0.8) 100%);
  border-radius: 50%;

  animation: rotate 2s linear infinite; 
}
@keyframes rotate {
  0% {
      transform:  rotate(0deg);
  }
  100% {
      transform:  rotate(360deg);
  }
}